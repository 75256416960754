import Signin from '@pages/auth/Signin';
import BaseLayout from '@containers/layout/BaseLayout';
import Header from '@containers/layout/BaseHeader';

// redirect referer
const routes = [
  { path: 'signin', element: Signin, props: { redirect: '/collect/forms/' } },
  {
    auth: true,
    wrapper: BaseLayout,
    wrapperProps: { headerSlot: Header },
    children: [
      {
        path: 'collect',
        breadcrumbName: '数据采集',
        children: [
          {
            breadcrumbName: '表单管理',
            path: 'forms',
            element: import('@pages/collect/form/FormList'),
            children: [
              {
                breadcrumbName: '新建表单',
                path: 'builder',
                element: import('@pages/collect/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    payload: { siderbar: false, breadcrumb: false },
                  },
                ],
              },
              {
                breadcrumbName: '编辑表单',
                path: 'builder/:id',
                element: import('@pages/collect/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    payload: { siderbar: false, breadcrumb: false },
                  },
                ],
              },
              {
                breadcrumbName: '编辑表单',
                path: 'clone/:id',
                element: import('@pages/collect/form/FormEditer'),
                emits: [
                  {
                    name: 'ChangeLayoutState',
                    payload: { siderbar: false, breadcrumb: false },
                  },
                ],
                props: { cloneMode: true },
              },
            ],
          },
          {
            breadcrumbName: '采集列表',
            path: 'list',
            element: import('@pages/collect/case/CaseList'),
            children: [
              {
                breadcrumbName: '录入病例',
                path: 'create',
                element: import('@pages/collect/case/CreateCase'),
              },
              {
                breadcrumbName: '录入自然人',
                path: 'createpeople',
                element: import('@pages/collect/case/CreateCase'),
              },
              {
                breadcrumbName: '病例详情',
                path: ':caseId',
                element: import('@pages/collect/case/CaseDetail'),
              },
              {
                breadcrumbName: '家系图',
                path: ':caseId/family',
                element: import('@pages/collect/case/family'),
              }
            ],
          },
          {
            breadcrumbName: '病例审核',
            path: 'review/cases',
            element: import('@pages/collect/review/CaseReviewList'),
            children: [
              {
                breadcrumbName: '审核详情',
                path: ':caseId',
                element: import('@pages/collect/review/CaseReviewDetail'),
              },
            ],
          },
          {
            breadcrumbName: '病例查看',
            path: 'cases',
            element: import('@pages/collect/check/CaseCheckList'),
            children: [
              {
                breadcrumbName: '病例详情',
                path: ':caseId',
                element: import('@pages/collect/check/CaseCheckDetail'),
              },
            ],
          },
          {
            breadcrumbName: '检测审核',
            path: 'review/detections',
            element: import('@pages/collect/review/DetectionReviewList'),
            children: [
              {
                breadcrumbName: '检测审核详情',
                path: ':orderId',
                element: import('@pages/collect/review/DetectionReviewDetail'),
              },
            ]
          },
          
          {
            breadcrumbName: '随访任务',
            path: 'follow',
            element: import('@pages/collect/follow/FollowList'),
            children: [
              {
                breadcrumbName: '随访详情',
                path: ':caseId',
                element: import('@pages/collect/case/CaseDetail'),
                props: { siderDefaultActiveKey: '2' },
              },
            ],
          },
          {
            breadcrumbName: '任务数据',
            path: 'taskdata',
            element: import('@pages/collect/follow/TaskData'),
          },
          {
            path: 'detectionapply',
            breadcrumbName: '检测申请',
            element: import('@pages/collect/review/DetectionApply'),
            children: [
              {
                breadcrumbName: '受检样品选用规则',
                path: 'samplerules',
                element: import('@pages/collect/review/SampleRules'),
              },
              {
                breadcrumbName: '创建检测申请单',
                path: 'createorder/:ordertype',
                element: import('@pages/collect/review/createOrder'),
              },
              {
                breadcrumbName: '检测申请详情',
                path: 'orderdetail/:orderId',
                element: import('@pages/collect/review/orderDetail'),
              },
            ]
          },
        ],
      },
      {
        path: 'genetic',
        breadcrumbName: '遗传资源管理',
        children: [
          {
            breadcrumbName: '项目管理',
            path: 'project',
            element: import('@pages/genetic/project/ProjectList'),
            children: [
              {
                breadcrumbName: '创建项目',
                path: 'create',
                element: import('@pages/genetic/project/CreateProject'),
              },
              {
                breadcrumbName: '项目详情',
                path: ':projectId',
                element: import('@pages/genetic/project/ProjectDetail'),
              },
              {
                breadcrumbName: '项目管理配置',
                path: 'config',
                element: import('@pages/genetic/project/ProjectConfig'),
              }
            ],
          },
          {
            breadcrumbName: '项目审核',
            path: 'projectaudit',
            element: import('@pages/genetic/project/ProjectAudit'),
            children: [
              {
                breadcrumbName: '项目详情',
                path: ':projectId',
                element: import('@pages/genetic/project/ProjectDetail'),
              }
            ],
          },
          {
            breadcrumbName: '科研成果',
            path: 'science',
            element: import('@pages/genetic/science/ScienceList'),
            children: [
              {
                breadcrumbName: '创建成果',
                path: ':type',
                element: import('@pages/genetic/science/ScienceEdit'),
              },
              {
                breadcrumbName: '成果详情',
                path: ':type/:scienceId',
                element: import('@pages/genetic/science/ScienceEdit'),
              },
            ],
          },
          {
            breadcrumbName: '遗传政策',
            path: 'policy',
            element: import('@pages/genetic/policy/PolicyList'),
          },
          {
            breadcrumbName: '项目导出',
            path: 'taskdata',
            element: import('@pages/genetic/project/TaskData'),
          }
        ],
      },
      // {
      //   path: 'feedback',
      //   breadcrumbName: '我发起的',
      //   element: import('@pages/collect/review/Feedback'),
      //   children: [
      //     {
      //       breadcrumbName: '受检样品选用规则',
      //       path: 'samplerules',
      //       element: import('@pages/collect/review/SampleRules'),
      //     },
      //     {
      //       breadcrumbName: '创建检测申请单',
      //       path: 'createorder/:ordertype',
      //       element: import('@pages/collect/review/createOrder'),
      //     },
      //     {
      //       breadcrumbName: '检测申请详情',
      //       path: 'orderdetail/:orderId',
      //       element: import('@pages/collect/review/orderDetail'),
      //     },
      //   ]
      // },
      // 新增
      {
        path: 'sysmanage',
        breadcrumbName: '系统管理',
        children: [
          {
            breadcrumbName: '人员管理',
            path: 'members',
            element: import('@pages/sysmanage/members/list'),
          },
          {
            breadcrumbName: '角色管理',
            path: 'roles',
            element: import('@pages/sysmanage/roles/list'),
          },
          {
            breadcrumbName: '项目授权',
            path: 'authproject',
            element: import('@pages/sysmanage/authproject/list'),
            children: [
              {
                breadcrumbName: '授权详情',
                path: ':projectId',
                element: import('@pages/sysmanage/authproject/Detail'),
              },
            ],
          },
          {
            breadcrumbName: '人员配置',
            path: 'peopleset',
            element: import('@pages/sysmanage/peopleset/index'),
          },
        ],
      },
      {
        breadcrumbName: '个人信息',
        path: 'personcenter',
        element: import('@pages/personcenter/index'),
      },
      {
        breadcrumbName: '修改密码',
        path: 'personcenter/password',
        element: import('@pages/personcenter/password'),
      },
      {
        breadcrumbName: '编辑信息',
        path: 'personcenter/editinfo',
        element: import('@pages/personcenter/editinfo'),
      },
      {
        path: 'theme',
        breadcrumbName: '门户网站',
        children: [
          {
            breadcrumbName: '主题风格',
            path: 'color',
            element: import('@pages/theme/Color'),
          },
          {
            breadcrumbName: '首页banner',
            path: 'banner',
            element: import('@pages/theme/Banner')
          },
          {
            breadcrumbName: '内容管理',
            path: 'content',
            element: import('@pages/theme/Content')
          }
        ],
      }
    ],
  },
];
export default routes;
