import DataSDK, { injectReqDataSDK } from './data';
import FileSDK, { injectReqFileSDK } from './file';
import LocalFileSDK from './file/local';
import FormSDK, { makeFormSDK } from './form';
import CaseSDK, { makeCaseSDK } from './case';
import LocalCaseSDK from './case/local';
import LocalSampleSDK from './sample/local';
import LocalFormSDK from './form/local';
import AuthSDK from './auth';
import SampleSDK, { makeSampleSDK } from './sample';
import ClinicSDK, { makeClinicSDK } from './clinic';
import LocalClinicSDK from './clinic/local';
import FollowSDK from './follow';
import DetectionSDK from './detection';
import ProjectSDK from './project';
import LoggerSDK from './logger';
import ScienceSDK from './science';
import PolicySDK from './policy';
import LabDetectionSDK from './lab/labdetection';
import UserSDK from './user';
import MemberSDK from './member';
import RolesSDK from './roles';
import WebsiteSDK from './website';
import authProjectUsersSDK from './authproject/authusers';
import userSiderSDK from './auth/usersiders';
import messageSDK from './message';
import ConfigapiSDK from './configapi';

import LocalCaseServe from '@common/serve/case';
import LocalSampleServe from '@common/serve/sample';
import LocalFormServe from '@common/serve/form';
import LocalFileServe from '@common/serve/file';
import LocalClinicServe from '@common/serve/clinic';

const createSDK = (base, account, project) => {
  return {
    form: FormSDK(base, account, project),
    case: CaseSDK(base, account, project),
    data: DataSDK(base, account, project, '/'),
    file: FileSDK(base, account, project),
    storagefile: FileSDK(base, account),
    auth: AuthSDK(base, account, project),
    sample: SampleSDK(base, account, project),
    clinic: ClinicSDK(base, account, project),
    follow: FollowSDK(base, account, project),
    detection: DetectionSDK(base, account, project),
    configapi: ConfigapiSDK(base, account, project),
    project: ProjectSDK(base, account, project),
    logger: LoggerSDK(base, account, project),
    science: ScienceSDK(base, account, project),
    policy: PolicySDK(base, account, project),
    labDetection: LabDetectionSDK(base, account),
    user: UserSDK(base, account),
    member: MemberSDK(base, account, project),
    roles: RolesSDK(base, account, project),
    website: WebsiteSDK(base, account, project),
    authProjectUsers: authProjectUsersSDK(base, account, project),
    usersiders: userSiderSDK(base, account, project),
    messageSDK: messageSDK(base, account, project)
  };
};

export const cretaeLocalSDK = (db, dbVersion) => {
  const caseServe = new LocalCaseServe(db);
  const sampleServe = new LocalSampleServe(db);
  const formServe = new LocalFormServe(db);
  const fileServe = new LocalFileServe(db);
  const clinicServe = new LocalClinicServe(db);

  return {
    case: LocalCaseSDK(caseServe),
    sample: LocalSampleSDK(sampleServe),
    form: LocalFormSDK(formServe),
    file: LocalFileSDK(fileServe),
    clinic: LocalClinicSDK(clinicServe),
  };
};

export const createGhostSDK = (req, base, account, project) => {
  const _FormSDK = makeFormSDK(req);
  const _CaseSDK = makeCaseSDK(req);
  const _SampleSDK = makeSampleSDK(req);
  const _ClinicSDK = makeClinicSDK(req);

  return {
    form: _FormSDK(base, account, project),
    case: _CaseSDK(base, account, project),
    sample: _SampleSDK(base, account, project),
    clinic: _ClinicSDK(base, account, project),
    data: injectReqDataSDK(req, base, account, '/'),
    file: injectReqFileSDK(req, base, account, project),
  };
};

export { FormSDK, CaseSDK, DataSDK, FileSDK, AuthSDK, SampleSDK };
export default createSDK;
