const sider = {
  menu: {
    theme: 'dark',
    mode: 'inline',
    items: [],
    // items: [
    //   { to: '/genetic/project', title: '项目管理', icon: 'dashboard' },
    //   { to: '/genetic/science', title: '科研成果', icon: 'baoguofahuo' },
    //   { to: '/genetic/policy', title: '遗传政策', icon: 'Clinic_icon' },
    //   { to: '/collect/forms', title: '表单管理', icon: 'wenjianjia' },
    //   { to: '/collect/list', title: '采集列表', icon: 'renwu' },
    //   { to: '/collect/cases', title: '病例查看', icon: 'Clinic_icon' },
    //   { to: '/collect/review/cases', title: '病例审核', icon: 'dashboard' },
    //   { to: '/collect/follow', title: '随访任务', icon: 'renwu' },
    //   { to: '/feedback', title: '我发起的', icon: 'wenjianjia' },
    //   {
    //     to: '/collect/review/detections',
    //     title: '检测审核',
    //     icon: 'dashboard'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/personal`,
    //     title: '管理中心',
    //     icon: 'dashboard'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/requests`,
    //     title: '检测申请单',
    //     icon: 'baoguofahuo'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/detections`,
    //     title: '检测管理',
    //     icon: 'Clinic_icon'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/laboratory`,
    //     title: '实验任务',
    //     icon: 'renwu'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/reportfile`,
    //     title: 'SOP文档',
    //     icon: 'wenjianjia' 
    //   },
    //   {
    //     link: `${window.location.origin}/lab/instruments`,
    //     title: '仪器设备',
    //     icon: 'yiqishebei'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/material_research`,
    //     title: '科研物料',
    //     icon: 'shijihaocai'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/material_daily`,
    //     title: '日常物料',
    //     icon: 'shijihaocai'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/sample_library`,
    //     title: '样本库',
    //     icon: 'Lab_icon'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/report`,
    //     title: '报告任务',
    //     icon: 'Link_icon'
    //   },
    //   {
    //     link: `${window.location.origin}/lab/monitoring`,
    //     title: '业务统计',
    //     icon: 'bingtu'
    //   },
    //   // {
    //   //   link: `${window.location.origin}/lab/members`,
    //   //   title: '成员管理',
    //   //   icon: 'user_icon',
    //   // },
    //   // 新增系统管理
    //   { to: '/theme/color', title: '主题风格' },
    //   { to: '/theme/banner', title: '首页banner' },
    //   { to: '/theme/content', title: '内容管理' },
    //   {
    //     to: '/sysmanage/members', title: '成员管理', icon: 'user_icon' },
    //   {
    //     to: '/sysmanage/roles', title: '角色管理', icon: 'user_icon' },
    //   {
    //     to: '/sysmanage/authproject', title: '项目授权', icon: 'dashboard'
    //   },
    //   {
    //     to: '/sysmanage/peopleset', title: '人员配置', icon: 'user_icon'
    //   },
    //   // {
    //   //   to: '/sysmanage/personcenter', title: '个人信息', icon: 'user_icon'
    //   // },
    //   // {
    //   //   to: '/sysmanage/personset', title: '个人信息', icon: 'user_icon'
    //   // },
    // ],
  },
};

export default sider;
